import { ConvertibleType, LegalEntityType } from '@generated/graphql';
import convertUnderscore from '@utils/convertUnderscore';
import { convertibleNames } from '@utils/convertibles';

export function makeConvertiblePlaceholderText({
  startup,
  convertibleType,
}: {
  startup?: string | null;
  convertibleType?: ConvertibleType | null;
}) {
  return convertibleType && startup
    ? `e.g. ${startup.split(' ')[0]} ${convertibleNames[convertibleType]} 2020`
    : convertibleType
      ? `e.g. ${convertibleNames[convertibleType]} 2020`
      : 'e.g. convertible note';
}

export function makeSearchEntityPlaceholderText(legalEntityType?: LegalEntityType | null) {
  return !legalEntityType
    ? 'Search entity'
    : legalEntityType === LegalEntityType.SPV
      ? 'Search SPV'
      : `Search ${convertUnderscore(legalEntityType, { case: 'lowerCase' })}`;
}

const LONG_TEXT_THRESHOLD = 11;

export function truncateLongNames(s: string) {
  return s.length > LONG_TEXT_THRESHOLD ? `${s.slice(0, LONG_TEXT_THRESHOLD)}...` : s;
}

export function includesSome(words: string[], search: string[]): boolean {
  const normalisedWords = words.map((str) => str.trim().toLowerCase());
  const normalisedSearch = search.map((str) => str.trim().toLowerCase());

  return normalisedSearch.some((searched) => normalisedWords.some((word) => word.includes(searched)));
}

export function getLocation({ country, city }: { country?: string | null; city?: string | null }) {
  if (!country && !city) {
    return null;
  }

  if (!country) {
    return city;
  } else if (!city) {
    return country;
  }

  return `${city}, ${country}`;
}

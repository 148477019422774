import styled, { css } from 'styled-components';

type TextType = 't1' | 't2' | 't3' | 't4' | 't5';
type TextWeight = 'regular' | 'bold';
type Color = 'default' | 'light' | 'gray' | 'white';
type Align = 'left' | 'right' | 'center';

interface BaseTextMixinProps {
  color?: Color;
  align?: Align;
}

const baseTextMixin = ({ color, align }: BaseTextMixinProps) => css`
  ${({ theme }) => css`
    ${color && `color: ${theme.colors.text[color]}`};
  `};
  ${align && `text-align: ${align}`};
`;

const h2Mixin = css<BaseTextMixinProps>`
  ${({ color, align }) => baseTextMixin({ color, align })};
  font-size: ${(props) => props.theme.fonts.h2.fontSize};
  line-height: ${(props) => props.theme.fonts.h2.lineHeight};
  font-weight: ${(props) => props.theme.fonts.h2.fontWeight};
`;

const H2 = styled.h2<BaseTextMixinProps>`
  ${h2Mixin};
`;

const h3Mixin = css<BaseTextMixinProps>`
  ${({ color, align }) => baseTextMixin({ color, align })};
  font-size: ${(props) => props.theme.fonts.h3.fontSize};
  line-height: ${(props) => props.theme.fonts.h3.lineHeight};
  font-weight: ${(props) => props.theme.fonts.h3.fontWeight};
`;

const H3 = styled.h3<BaseTextMixinProps>`
  ${h3Mixin};
`;

interface TextMixinProps extends BaseTextMixinProps {
  type?: TextType;
  weight?: TextWeight;
}

const textMixin = ({ type = 't1', weight = 'regular', color, align }: TextMixinProps | undefined = {}) => css`
  ${baseTextMixin({ color, align })};
  ${({ theme }) => css`
    font-size: ${theme.fonts[type][weight].fontSize};
    line-height: ${theme.fonts[type][weight].lineHeight};
    font-weight: ${theme.fonts[type][weight].fontWeight};
  `};
`;

type TextProps = TextMixinProps;

const Text = styled.p<TextProps>`
  ${({ type, weight, color, align }) => textMixin({ type, weight, color, align })};
`;

export { H2, H3, Text, h2Mixin, textMixin };
